// Imports
@import "../../shared/shared.scss";

.textarea {
    padding: 1em;
    width: 100%;
    font-size: 0.9em;
    border: 0;
    background-color: transparent;
    color: $dark-blue;
    transition: 250ms box-shadow;
    border-radius: 4px;   
}

.guestbook__message {
    min-height: 50vh;
    overflow: auto;
    position: relative;
    z-index: 1;
    line-height: 140%;
    &::placeholder {
        color: $dark-blue;
    }
}

.guestbook__message--background-white {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparentize($white, 0.25);
    transform: skew(3deg) rotate(2deg);
    border-radius: 4px;
}