/*-------------------------------------------------------------------*/
/* === Import template variables === */
@import "./Style/_variables.scss";

/*-------------------------------------------------------------------*/
/* === Mixins === */
@import "./Style/mixins/_buttons.scss";
@import "./Style/mixins/_cards.scss";
@import "./Style/mixins/_badges.scss";

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "./Style/_misc.scss";
@import "./Style/_sidebar.scss";
@import "./Style/_navbar.scss";
@import "./Style/_footer.scss";
@import "./Style/_typography.scss";

/*-------------------------------------------------------------------*/
/* === Components === */
@import "./Style/components/_buttons.scss";
@import "./Style/components/_cards.scss";
@import "./Style/components/_tables.scss";
@import "./Style/components/_badges.scss";
@import "./Style/components/_forms.scss";

.page-content:not(:first-child) {
    margin-top: 2em;
}

.table-responsive{
    z-index: 99999;
    overflow: visible
}

.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;   
}