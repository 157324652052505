.is-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang__wrapper {
    text-align: center;
}

.stick {
    img {
        max-width: 200px;
    }
}

.link {
    display: block;
    position: relative;
}

.link-dutch {
    top: 80px;
    img {
        max-width: 200px;
    }
}

.link-frans {
    top: 230px;
    img {
        max-width: 200px;
    }
}