
// Imports
@import "../../shared/shared.scss";

// .videochat {
//     background-image: url('/assets/illustrations/videochat.svg');
//     background-repeat: no-repeat;
//     background-position: top center;
//     background-size: contain;    
//     width: 100%;
//     height: 80%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     p {
//         color: $dark-blue;
//         font-weight: bold;
//     }
// }

    .error__reminder {
        display: block;
        padding:10%;
        background-color: $white;
    }

    .error__btn {
        margin-top:5%;
        width: 30%;
    }

.videochat {
    z-index:-1;
    overflow: hidden;
	height: 100%;
    background-color: black;
    margin: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    video {
        object-fit: cover;
    }

    .reservation__reminder {
        position:absolute;
        text-align: center;
        color: $dark-blue;
        font-size: 1.4em;
        line-height: 140%;
        margin: 2em 0;
        color: $blue;
        font-weight: bold;
    
        span {
            color: $blue;
            font-weight: bold;
            font-size: 1.8em;
            position: relative;
            top: 6px;
        }
    }

    .my-video {
        width: 20%;
        position: absolute;
        z-index: 2;
        bottom: 10px;
        left: 10px;
        display: block;
        border-radius: 10px;
        border: 2px solid white;
        box-shadow: 0px 3px 10px 0px #00000036;
    }
    .user-video {
        transform: translateX(-50%);
        width: 50%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        video {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0px;
            min-height: 0px;
        }
    }

    .videoHolder {
        width: 100%;
        height: 100%;
        background-color:#CCCCCC;
        margin:10px;
        display:inline-block;
        float:left;
        position:relative;
    }
    .videoHolder video {
        max-width: 100%;
        max-height:100%;
        margin: auto;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }

    .videochat__controls {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        .videochat__btn {    
            z-index: 9999;
            width: 70px;
            height: 70px;
            margin: 1em;
        }
        .videochat__call {
        }
        .videochat__answer {
            display: none;
        }
        .videochat__reject {
            display: none;
        }
    }
}

.videochat__container {    
    background-color: rgba(0, 13, 56, 0.51);
    position: fixed;
    width: 100%;
    height: 100vh;
    padding: 2em 1em;
    top: 0;
    left: 0;
    z-index: 999999;
    .videochat__notification {
        padding: 20px;
        background-color: $light-green;
        height: calc(100vh - 4em);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        // max-width: 500px;
        margin: 0 auto;
        .videochat__title {
            padding: 1em;
            color: $dark-blue;
            font-size: 1.2em;
            width: 100%;
        }
        .videochat__content {
            text-align: center;
            padding: 2em 1em;
            align-self: center;
            width: 100%;
            img {    
                max-width: 100px;
                animation: shake 3s ease-in-out infinite;
            }
            .videochat__message {
                color: $blue;
                font-size: 1.6em;
                line-height: 145%;
                padding: 2em 0 0;
            }
        }
        .videochat__controllers {
            display: flex;
            justify-content: center;
            align-self: center;
            width: 100%;
            a {
                text-align: center;    
                margin: 1em 2em;
                align-self: flex-end;
                img {
                    width: 70px;
                    display: block;
                    text-align: center;
                    margin: 0 auto;
                }
            }
            .deny {
                color: $pink;
            }
            .accept {
                color: $dark-blue;
            }
        }
    }
}

@keyframes shake {
    0% {
        transform: rotate(0)
    }

    5% {
        transform: rotate(-10deg)
    }

    10% {
        transform: rotate(10deg)
    }

    15% {
        transform: rotate(-10deg)
    }

    20% {
        transform: rotate(10deg)
    }

    25% {
        transform: rotate(0)
    }

    50% {
        transform: rotate(0)
    }

    55% {
        transform: rotate(10deg)
    }

    60% {
        transform: rotate(-10deg)
    }

    65% {
        transform: rotate(10deg)
    }

    70% {
        transform: rotate(-10deg)
    }

    75% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(0)
    }
  }