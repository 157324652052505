.clown__guestbook__message {
    white-space: pre-line;
    p {
        font-size: 1rem;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.blockquote-primary {
    border-color: #1bcfb4;
    .blockquote-footer {
        color: #1bcfb4;
    }
    cite {
        font-style: italic;
    }
}