// Colors

$dark: #121918;
$pink: #D2136D;
$green: #69F4C3;
$light-green: #ACF2E8;
$dark-green: #45DE9F;
$blue: #2759E8;
$dark-blue: #0C2B96;
$white: #FFFFFF;

// Media Queries

$tablet: 768px;
$desktop: 1024px;
