// Imports
@import "../../shared/shared.scss";

.video__item {
    position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
    height: 0;
    margin-bottom: 80px;
    
    .video__item__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;

        iframe {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.video__snippet {
    background-color: transparentize($white, 0.25); 
    padding: 0.8em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -3px;
    min-height: 60px;

    p {
        font-size: 0.8em;
        line-height: 140%;
        color: $dark-blue;
    }
}

@media (min-width: $tablet) {

    .video__item {
        padding-bottom: 20%;
        width: 30%;
    }

}

@media (min-width: $desktop) {

    .video__item {
        width: 30%;
    }

}