// Imports
@import "./shared/shared.scss";

// App Styling

*{
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body{
  background-color: $light-green;
}

h1,
h2,
h3,
h4,
a,
p,
input,
button,
div {
  font-family: 'Mali', cursive!important;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  color: $pink;
}

.is-p {    
  font-size: 1.4em;
  line-height: 140%;
}

img {
  width: 100%;
  height: 100%;
}

.screen__nav {
  .container {
    padding: 1em;
  }
}

.container {    
  padding: 2em 1em 5em;
}

@media (min-width: $tablet) {

  .container {
      padding: 3em;
  }

  .container--flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
  }

  // li {
  //     width: 30%;
  // }

}

@font-face {
  font-family: 'chalkduster';
  src: url('./Chalkduster.ttf');
}

@media (min-width: $desktop) {
  .container {
      // padding: 4em;
  }
}

@media (min-width:1824px) {
  .container {
      // padding: 5em;
  }
}