
// Imports
@import "../../shared/shared.scss";

  .navbar-navigation{
    position: fixed;    
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .navigation-clown{
    background: url(/assets/icons/clown-bg.png);
  }
  
  .navigation-videos{
    background: url(/assets/icons/videos-bg.png);
  }
  
  .navigation-cam{
    background: url(/assets/icons/cam-bg.png);
  }
  
  .navigation-agenda{
    background: url(/assets/icons/agenda-bg.png);
  }
  
  .navigation-gastenboek{
    background: url(/assets/icons/gastenboek-bg.png);
  }
  
  .navigation-settings{
    background: url(/assets/icons/settings-bg.png);
  }
  
  .navigation-link{
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    width: 100%;
    // padding: 6px;
    // width: 60%;
    // background-repeat: no-repeat;
    // background-size: contain;
  }

  .navigation-item {
    padding: 6px 0;
    width: auto;
  }
  
  .custom-icon-large {
    // width: 120%;
    // left: -3px;
    // position: relative;
  }

  .custom-icon-with-top {
    width: 90%;
    position: relative;
    left: 3px;
    top: -4px;
  }

  .custom-icon-without-top {
    top: 0;
    width: 90%;
    position: relative;
    left: 3px;
  }

@media (min-width: $tablet) {
  .navigation-link{
    width: 100%;
  }
}


@media (min-width: $desktop) {

  .main-navigation {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 60px;
    background-color: white;
    align-items: center;
    display: flex;
  }

  .navbar-navigation {
    position: relative;
    // top: 50px;
    width: auto;
    align-items: center;
    align-self: flex-start;
    position: relative;

    .navigation-item {
      width: 60px;
      padding: 10px;
    }
  }
}