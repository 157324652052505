// Imports
@import "../../shared/shared.scss";

// Confirmation

.confirmation__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
}

.confirmation__wrapper {
    text-align: center;
    position: relative;
    top: -10%;
    img {   
        max-width: 150px;
    }
    h1 {  
        max-width: 230px;
    }
}

.confirmation__message {
    font-weight: bold;
    margin-top: 2em;
}

.confirmation__message--state-blue {
    color: $blue;
}

.confirmation__message--state-pink {
    color: $pink;
}

// Form

.form {
    max-width: 100%;
    margin: 0 auto;
    width: 400px;
}

.input__group {
    margin-top: 1.2em;
}

.input__field {
    position: relative;    
}

.input__field:not(:first-child) {
    margin-top: 0.6em;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 3px lighten($dark-blue, 20); 
}

.input::placeholder {
    color: $dark-blue;
    opacity: 0.8;
}

.input {
    padding: 1em 1em 1em 3em;
    width: 100%;
    font-size: 0.9em;
    border: none;
    background-color: transparentize($blue, 0.82); 
    color: $dark-blue;
    transition: 250ms box-shadow;
    border-radius: 4px;
}

.checkbox {    
    margin-right: 0.8em;
    margin-top: 0.2em;
}

.icon {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 13px;
    left: 10px;
}

.icon--state-relative {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.icon--type-user {
    background-image: url('/assets/icons/user.svg');
}

.icon--type-clown {
    background-image: url('/assets/icons/clown-icon.svg');
}

.icon--type-department {
    background-image: url('/assets/icons/department.svg');
}

.icon--type-mail {
    background-image: url('/assets/icons/email.svg');
}

.icon--type-password {
    background-image: url('/assets/icons/password.svg');
}

.icon--type-phone {
    background-image: url('/assets/icons/phone.svg');
}

.icon--type-videochat {
    background-image: url('/assets/icons/videochat.svg');
}

.input__field--checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.primary-link {
    color: $pink;
    text-decoration: underline;
    width: 100%;
    font-size: 0.7rem;
    line-height: 145%;
}

.secondary-link {
    margin-top: 1em;
    text-align: left;
}

.app__btn__group {
    margin-top: 1em;
}

.app__btn {
    margin-top: 1em;
    width: 100%;
    text-align: center;
    padding: 1em;
    font-size: 1em;
    border-radius: 4px;
}

.app__btn--state-normal {
    background-color: $pink;
    color: $white;
    font-weight: bold;
    border: none;
    box-shadow: none;
}

.app__btn--state-disabled {
    background-color: #696969;
    color: $white;
    font-weight: bold;
    border: none;
    box-shadow: none;
}

.app__btn--state-outline {
    background-color: transparent;
    color: $pink;
    border: 3px solid $pink;
    box-shadow: none;
    padding: calc(1em - 3px);
}

.text--with-link {
    font-size: 0.8em;
    margin-top: 1em;
    p {
        color: $dark-blue;
        display: inline-block;
    }
    a {
        color: $pink;
        display: inline-block;
    }
}

// Toast

.custom__toast {
    padding: 1em!important;
    border-radius: 4px;

    .Toastify__toast {
        background-color: $pink;
        border-radius: 4px;

        .Toastify__toast-body {
            line-height: 140%;
        }
    }
}