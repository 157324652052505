// Imports
@import "../../shared/shared.scss";

.background{
    position: absolute;
    z-index: 0;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
}
  
.background img {    
    margin: 0 auto;
    text-align: center;
}
  
.background--mobile {
    max-width: 400px;
}
  
.background--tablet {
    display: none;
}

.background--desktop {
    display: none;
}

.logo{
    background-image: url(/assets/logo/logo.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 50px;
    width: auto;
}

.logo p{
    visibility: hidden;
}

.map{
    position: relative;
}

.map-element{
    position: absolute;
    transition: transform .25s ease-in-out;
    img {
        max-width: calc(100vw - 55vw);
        width: 130px;
    }
}

#clowns{
    top: 200px;
    right: calc(100vw - 90vw);
    //animation-delay: 2s;
    animation-delay: 0s;
}

#tipi{
    top: 800px;
    right: calc(100vw - 90vw);
    //animation-delay: 6s;
    animation-delay: 0s;
}

#airballoon{
    top: 1550px;
    left: calc(100vw - 70vw);
    //animation-delay: 3s;
    animation-delay: 0s;
}

#caravan{
    top: 2400px;
    left: calc(100vw - 70vw);
    //animation-delay: 7s;
    animation-delay: 0s;
}

#parasol{
    left: 60%;
    top: 3000px;
    //animation-delay: 8s;
    animation-delay: 0s;
}

/* #tent{
    right: 0;
    top: calc(510vh - 41px - 40.5px);
} */

#sign{
    right: 20%;
    top: 3800px;
    //animation-delay: 10s;
    animation-delay: 0s;
}

.bounce {
    animation-name: bounce;
    animation-duration: 6.2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 3);
    transition-timing-function: ease-out;
}

@keyframes bounce {
    0%   { transform: scale(1,1)        translateY(0); }
    2%  { transform: scale(1.1,.9)     translateY(0); }
    6%  { transform: scale(0.9,1.1)    translateY(-30px); }
    10%  { transform: scale(1.2,1)      translateY(0); }
    13%  { transform: scale(1,1)        translateY(-0.4px); }
    16%  { transform: scale(1,1)        translateY(0); }
    20% { transform: scale(1,1)        translateY(0); }
    100% { transform: scale(1,1)        translateY(0); }
}

@media (min-width: $tablet) {

    .background--mobile {
        display: none;
    }

    .background--desktop {
        display: none;
    }
    
    .background--tablet {
        display: block;
    }
  
    .map-element img {
        max-width: 180px;
        width: 180px;
    }

}

@media (min-width: $desktop) {

    .background {
        width: $desktop;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
    }

    .background--mobile {
        display: none;
    }
    
    .background--tablet {
        display: none;
    }

    .background--desktop {
        display: block;
    }

    .map-element{
        img {
            width: 120px;
        }
    }

    #clowns{
        top: 60px;
        right: 60%;
    }

    #tipi{
        top: 0;
        right: 20%;
    }

    #airballoon{
        top: 300px;    
        left: 60%;
    }

    #caravan{
        top: 450px;
        left: 30%;
    }

    #parasol{
        left: 20%;
        top: 700px;
    }

    /* #tent{
        right: 0;
        top: calc(510vh - 41px - 40.5px);
    } */

    #sign{
        right: 20%;    
        top: 600px;
    }
}