// Imports
@import "../../shared/shared.scss";

.guestbook {

}

.guestbook__container {

}

.message__container {
    padding: 1em;
    width: 100%;
    display: inline-block;
    position: relative;    
    height: 310px;
}

.message__content {
    padding: 1em;
    border: none;
    height: 260px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
}

.message__text {
    color: $dark-blue;    
    line-height: 140%;
    font-size: 0.9em;
    white-space: pre-line;
    position: relative;
    z-index: 1;
}

.message__user {    
    margin-top: 2em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
}

.message__background {
    background-color: transparentize($white, 0.25);    
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    //transform: skew(-1deg) rotate(1deg);
    border-radius: 4px;
    z-index: 0;
}

.message__background::before {
    content: "";
    padding: 10px;
    width: 105%;
    height: 105%;
    position: absolute;
    top: -2.5%;
    left: -2.5%;
    // border: 3px solid $dark-blue;
    border-radius: 4px;
}

.message__user--name {
    font-size: 1em;
    margin: 0;
    line-height: 140%;
    color: $dark-blue;
}

.message__date {
    color: $dark-green;
    span {
        margin-left: 1.2em;
    }
}

.icon--state-normal {
    position: relative;
    padding: 0.8em;
    background-repeat: no-repeat;
}

.writer {
    // position: absolute;
    // bottom: 1.6em;
    position: relative;
    z-index: 1;
    .icon--state-normal {
        padding: 0.6em;
    }
}

.guestbook__tabs {
    display: flex;
    justify-content: space-around;    
    padding: 2em 0;
}

.tab {
    border: none;
    padding: 5px 10px;
    font-size: 0.9em;
    font-weight: bold;
    text-transform: capitalize;
    color: $dark-blue;
    background-color: transparent;
    opacity: 0.65;
}

.tab:focus {
    outline: none;
}

.tab--state-selected {
    border: none;
    opacity: 1;
    // border-bottom: 3px solid $dark-blue;
}


