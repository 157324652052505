// Imports
@import "../../shared/shared.scss";

.error-404 {
    height: 80%;
    display: flex;
    align-content: center;
    align-items: center;
    h2 {
        color: $pink;
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        line-height: 145%;
    }
}