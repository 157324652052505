// Imports
@import "../../shared/shared.scss";

// CSS masonry effect
.clowns__list {
    column-count: 2;
    column-gap: 0;
    list-style: none;
	display: flex;
	flex-wrap: wrap;
    .clowns__item {
        break-inside: avoid;
        padding: 5px;
        width: 33.3%;
        .item__link {
            padding: 5px;
        }
    }
}

.clown__info {
    ul {
        li {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;
            p {
                margin-left: 1em;
                color: $dark-blue;
                line-height: 145%;
            }
        }
    }
}

.clown__description {
    color: $dark-blue;
    margin-top: 2em;
    .clown__description--subtitle {
        font-weight: bold;
        margin-bottom: 0.8em;
    }
    .clown__description--text {
        line-height: 130%;
        font-size: 0.9em;
    }
}

.clown__avatar {
    text-align: center;
    margin-bottom: 1.5em;
    img {
        width: 50%;
    }
}

@media (min-width: $tablet) {

    .clowns__list {
        column-count: 3;
        column-gap: 0;
    }

}

@media (min-width: $desktop) {

    .clowns__list { 
        .clowns__item {
            .item__link {
                padding: 2em;
            }
        }
    }
    
}