/* Page Transition */

.mainBg {
    background-color: #ACF2E8;
}

.page {    
    // scroll-behavior: smooth;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transition: transform 0.25s cubic-bezier(0.4, 0.0, 0.2, 1), opacity 0.25s ease-in-out;
}

.first.page-enter {
    transform: translate(-100%, 0) scale(0.8);
    opacity: 0;
}

.first.page-enter-active {
    transform: translate(0, 0) scale(1);
    opacity: 1;
}

.first.page-exit {
    transform: translate(-100%, 0) scale(0.8);
    opacity: 0;
}

.second.page-enter {
    transform: translate(100%, 0) scale(0.8);
    opacity: 0;
}

.second.page-enter-active {
    transform: translate(0, 0) scale(1);
    opacity: 1;
}

.second.page-exit {
    transform: translate(100%, 0) scale(0.8);
    opacity: 0;
}

.third.page-enter {
    transform: translate(100%, 0) scale(0.8);
    opacity: 0;
}

.third.page-enter-active {
    transform: translate(0, 0) scale(1);
    opacity: 1;
}

.third.page-exit {
    transform: translate(100%, 0) scale(0.8);
    opacity: 0;
}

.middle.page-enter {
    transform: translate(-100%, 0) scale(0.8);
    opacity: 0;
}

.middle.page-enter-active {
    transform: translate(0, 0) scale(1);
    opacity: 1;
}

.middle.page-exit {
    transform: translate(100%, 0) scale(0.8);
    opacity: 0;
}