
// Imports
@import "../../shared/shared.scss";

.screen__nav {
  border-bottom: 3px solid $dark-blue;
}

.screen__nav_noborder {
  border: none;
}
  
.screen__nav--list {
  display: flex;
  justify-content: space-between;    
  align-items: center;
  li {
    position: relative;
  }
}
  
.screen__nav-icon {
  width: 2.5em;
  background-image: url('/assets/icons/arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0.8em;
}

.screen__nav-title {
  color: $dark-blue;
  font-weight: bold;
  font-size: 1.2em;
  text-align: right;
}

.screen__nav-title::first-letter {
  text-transform: capitalize;
}

.back__image {
  width: 50px;
  position: absolute;
}